


















import BaseCard from "@/components/Base/BaseCard.vue";
import BaseListTwoColumn from "@/components/Base/BaseListTwoColumn.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedProposalInfoReceivedFundItem from "./InfoReceivedFundItem.vue";

@Component({
    components: {
        BaseCard,
        BaseListTwoColumn,
        SharedProposalInfoReceivedFundItem,
    },
})
export default class SharedProposalInfoReceivedFund extends Vue {
    @Prop() proposal!: any;

    get receivedFund() {
        let receivedFund: number = 0;

        if (
            this.proposal &&
            this.proposal.funds &&
            this.proposal.funds.length
        ) {
            this.proposal.funds.map((fund: any) => {
                receivedFund += fund.nominal;
            });
        }

        return receivedFund;
    }

    get receivedFundInfo() {
        let receivedFund: any = {
            text: "Dana Masuk",
            value: this.receivedFund,
            type: "currency",
            valueClass: "title font-weight-bold",
        };

        return receivedFund;
    }
}
